

@keyframes pop {
  0% {
    transform: scale();
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Wrapper {
  display: flex;
  margin: 10px;
  box-sizing: border-box;
border-radius: 5px;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.05;
    --box-shadow: 13px 13px 13px #ccc;
    --box-shadow-picked-up: 20px 20px 20px #000;
    z-index: 999;
  }
}

.Item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 18px 20px;
  margin-left: 3px;
  background-color: #fff;
  box-shadow: 3px 3px 5px #ddd;
  outline: none;
  
  box-sizing: border-box;
  list-style: circle;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  color: #333;
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px #4c9ffe, 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    opacity: var(--dragging-opacity, 0.5);
    z-index: 0;

    /* &:focus { */
      box-shadow: 5px 5px 20px #ccc;
    /* } */
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;
    &:focus {
      box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);;
    }
    cursor: not-allowed;
  }

  &.dragOverlay {
    cursor: inherit;
    /* box-shadow: 0 0px 6px 2px #4c9ffe;; */
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(var(--scale));
    box-shadow: 5px 5px 20px #ccc;
    opacity: 1;
  }

  &.color:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--color);
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
  }
}

.Remove {
  visibility: hidden;
}

.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}
